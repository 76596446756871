import templates from './templates'
import { readAsByteArray } from './LocalFileLoader'
import luaSimpleXorEncrypt from './LuaSimpleXorEncrypt'
import { saveAs } from 'file-saver'
import makeid from './generateId'

const elFile = document.getElementById('file')
const elEncrypt = document.getElementById('encrypt')
const elFileName = document.getElementById('file-name')

elFile.addEventListener('change', () => {
	if (elFile.files[0]) {
		elFileName.textContent = elFile.files[0].name
	}
})

elEncrypt.addEventListener('click', () => {
	if (elFile.files[0]) {
		readAsByteArray(elFile.files[0], (bytes, file) => {
			let latestGlobal = makeid(Math.floor(Math.random()) + 10)
			let hideGlobal = `${latestGlobal}=_G;`

			let encrypted = ''
			for (let i = 0; i < Math.floor(Math.random() * 5) + 5; i++) {
				const result = luaSimpleXorEncrypt(
					[].slice.call(new TextEncoder('utf-8').encode(templates.randomLuaCode)),
					makeid(5),
					latestGlobal,
					hideGlobal,
				)
				encrypted += `${result.encrypted};`
				latestGlobal = result.latestglobal
				hideGlobal = `${makeid(Math.floor(Math.random()) + 10)}=${latestGlobal};`
			}

			const result = luaSimpleXorEncrypt(bytes, makeid(5), latestGlobal, hideGlobal)
			encrypted += `${result.encrypted};`
			latestGlobal = result.latestglobal
			hideGlobal = `${makeid(Math.floor(Math.random()) + 10)}=${latestGlobal};`

			for (let i = 0; i < Math.floor(Math.random() * 2); i++) {
				const result = luaSimpleXorEncrypt(
					[].slice.call(new TextEncoder('utf-8').encode(templates.randomLuaCode)),
					makeid(5),
					latestGlobal,
					hideGlobal,
				)
				encrypted += `${result.encrypted};`
				latestGlobal = result.latestglobal
				hideGlobal = `${makeid(Math.floor(Math.random()) + 10)}=${latestGlobal};`
			}

			const blob = new Blob([encrypted], {
				type: 'application/octet-stream',
			})
			saveAs(blob, file.name)
		})
	}
})
