function readAsByteArray(file, callback) {
	const reader = new FileReader()
	reader.onload = (e) => {
		const arrayBuffer = e.target.result
		const uint8Array = new Uint8Array(arrayBuffer)
		const array = [].slice.call(uint8Array)
		callback?.(array, file)
	}
	reader.readAsArrayBuffer(file)
}

export { readAsByteArray }
