import utf8 from 'utf8'
import luamin from 'luamin'
import simpleXorEncrypt from './SimpleXorEncrypt'
import shuffleWithKey from './ShuffleWithKey'
import templates from './templates'
import makeid from './generateId'
const converter = require('hex2dec')

function luaSimpleXorEncrypt(bytes, key, latestGlobal, hideGlobal) {
	function randomInt(length) {
		return Math.floor(
			4 ** (length - 1) + Math.random() * Number.parseInt((4 ** (length - 1)).toString().replace('1', '9')),
		)
	}

	let Key53 = randomInt(4)
	let Key14 = randomInt(2)

	const inv256 = new Array(128).fill(-1).map((_, i) => {
		let inv = -1
		while ((inv * (2 * i + 1)) % 256 !== 1) {
			inv += 2
		}
		return inv
	})

	function encode(str) {
		function localEncode() {
			let K = Key53
			const F = Key14 + 16384
			let result = ''

			for (let i = 0; i < str.length; i++) {
				const L = K % 274877906944
				const H = (K - L) / 274877906944
				const M = H % 128
				const c = (str.charCodeAt(i) * inv256[M] - (H - M) / 128) % 256
				K = L * F + H + c + str.charCodeAt(i)
				const encoded = converter.decToHex(Math.abs(c).toString(), { prefix: false }).toUpperCase()
				result += encoded.length === 1 ? `0${encoded}` : encoded
			}

			return result
		}

		let encoded
		let generated = false
		while (!generated) {
			encoded = localEncode()
			if ((encoded.match(/-/g) || []).length === 0) {
				generated = true
			} else {
				Key53 = randomInt(4)
				Key14 = randomInt(2)
			}
		}

		return encoded
	}

	function generateCode() {
		return makeid(Math.floor(Math.random() * 2) + 10)
	}

	const randomLoad = generateCode()
	const encrypted = simpleXorEncrypt(bytes, utf8.encode(key))
	const shuffled = shuffleWithKey(encrypted, key)

	const amountToObfuscate = Math.floor(Math.random() * 10)
	for (let i = 0; i < amountToObfuscate; i++) {
		const randomized = generateCode()
		hideGlobal += `${randomized}=${latestGlobal};`
		latestGlobal = randomized
	}

	let code = `${hideGlobal}${templates.decoder.replace(/cb/g, generateCode())}${templates.load
		.replace(/globalload/g, randomLoad)
		.replace('key', `"${key}"`)
		.replace('bytecode', shuffled.join(','))}`

	const generateCodes = [
		'getCode',
		'resultDataBytes',
		'resultDecoded',
		'resultStringBytes',
		'bmStringResult',
		'hideBytesThingy',
		'bmString',
		'decode',
		'getVMB',
		'gcn',
		'VM_PRSC',
		'errorHandler',
	]
	for (const k of generateCodes) {
		code = code.split(k).join(generateCode())
	}
	code = code.replace(/loadGlobal/g, latestGlobal)
	code = code.replace(/Key14/g, Key14.toString())
	code = code.replace(/Key53/g, Key53.toString())

	const needsEncode = ['string', 'char', 'load']
	for (const k of needsEncode) {
		for (let i = 0; i < k.length; i++) {
			code = code.split(k + (i + 1)).join(encode(k.charAt(i)))
		}
	}

	code = code.replace(/print/g, generateCode())
	code = code.replace(/getParamsFunction/g, generateCode())
	code = code.replace(/resultParams/g, generateCode())
	code = code.replace(/currentByte/g, generateCode())
	return {
		encrypted: luamin.minify(code),
		hideglobal: hideGlobal,
		latestglobal: latestGlobal,
	}
}

export default luaSimpleXorEncrypt
