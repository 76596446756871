import templatesString from "./data/templates.lua";

const templates = {
	load: "",
	decoder: "",
	randomLuaCode: "",
};

for (const value of templatesString
	.replace(/\r\n/g, "\n")
	.split("--------------------")) {
	if (value.startsWith(" Template: ")) {
		const newlinePos = value.indexOf("\n");
		const name = value.substring(" Template: ".length, newlinePos);
		templates[name] = value.substring(newlinePos + 1);
	}
}

export default templates;
