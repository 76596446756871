function makeid(length) {
	let result = String.fromCharCode(Math.floor(Math.random() * (122 - 97)) + 97)
	const characters = 'aBcDeFgHiJkL'
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * 17))
	}
	return result
}

export default makeid
